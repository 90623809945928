<template>
  <main>
    <SectionHero :variant="hero.variant">
      <OrganismHero :hero="hero" />
    </SectionHero>
    <client-only>
      <SectionDefault>
        <Builder :blocks="blocks" />
      </SectionDefault>
    </client-only>
  </main>
</template>

<script>
import Builder from '../../components/builder/Builder'
import pageBuilder from '../../components/mixins/pageBuilder.js'
import OrganismHero from '../../components/organisms/layouts/OrganismHero'
import SectionDefault from '../../components/templates/layouts/SectionDefault'
import SectionHero from '../../components/templates/layouts/SectionHero'

const mixinPageBuilder = pageBuilder({
  pageUrl: 'myzone',
})

export default {
  name: 'FitnessMyzone',
  components: {
    SectionDefault,
    Builder,
    SectionHero,
    OrganismHero,
  },
  mixins: [mixinPageBuilder],
  data: c => {
    return {
      hero: {
        variant: 'transparent-parallax',
        title1: 'мониторинг',
        title2: 'MYZONE®',
        image: 'fitness/myzone/hero.webp',
        imageMobile: 'fitness/myzone/hero-mobile.jpg',
        breadcrumbs: c.$breadcrumbs.myzone(),
      },
    }
  },
}
</script>
